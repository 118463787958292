import React from 'react'
import "./Card.css"
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import PersonIcon from '@material-ui/icons/Person';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import GavelIcon from '@material-ui/icons/Gavel';
function Card() {
    return (
        <div>
            <div className="cont__service">
                <h2 className="col__text">Servizi</h2>
            </div>
            <div className="container__service">
                <div className="card">
                    <div className="face face1">
                        <div className="content">
                            <ImportantDevicesIcon style={{ fontSize: 70}}/>
                            <h3>Web Developing</h3>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <p>Realizzo il tuo sito web, in base alle tue necessità in modo da portare la tua attivita a un livello superiore.</p>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="face face1">
                        <div className="content">
                            <GavelIcon style={{ fontSize: 70}}/>
                            <h3>Restyling sito Web</h3>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <p>Hai gia un sito ma vuoi effettuare delle modifiche funzionali o rivoluzionare lo stile, il servizio di restyling è il servizio giusto per te</p>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="face face1">
                        <div className="content">
                            <AddShoppingCartIcon style={{ fontSize: 70}}/>
                            <h3>E-Commerce</h3>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <p>Possedere un E-Commerce ti permette di ampliare in maniera esponenziale le tue possibilità permettendoti di vendere anche a persone a kilometri di distanza da te</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Card;
